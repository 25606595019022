import axios from "axios";
import { APIS, EnvKey } from "./const";
import jsonwebtoken from "jsonwebtoken";
import store, { userAction } from "../Redux/store";
import { navigation } from "./const";
import crypto from "crypto";

// export const getJWT = (body) => {
//   // const jwtToken = sign({}, process.env.NEXT_PUBLIC_JWT_KEY, {
//   //   algorithm: "HS256",
//   //   // expiresIn: "60s",
//   //   expiresIn: "7200s",
//   // });
//   // return jwtToken;
// };

export const createCryptoSign = (value) => {
  try {
    const privateKey = process.env.NEXT_PUBLIC_SIGN_KEY;
    const h = crypto.createSign("sha256");
    let data = JSON.stringify(value);

    h.update(data);

    const signature = h.sign(privateKey, "hex");
    return signature;
  } catch (err) {}
};

export const api = axios.create({
  baseURL: APIS.baseURL,
});

api.interceptors.request.use(
  async (config) => {
    const EncodedToken = localStorage.getItem("UT_UI");
    let Token = "";
    if (EncodedToken) {
      let SessionData = jsonwebtoken.verify(EncodedToken, EnvKey.secretKey);
      Token = SessionData.token;
    }
    const data = config.method === "get" ? config.url : config.data;
    let sign = createCryptoSign(data);

    if (Token) {
      config.headers["Authorization"] = `Bearer ${Token}`;
    }

    const state = store.getState();
    const locale = state.lang?.locale;
    config.headers["accept-language"] = locale;
    config.headers["x-signature"] = sign;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;

    const { id, refreshToken, phone } = jsonwebtoken.verify(
      localStorage.getItem("UT_UI"),
      EnvKey.secretKey
    );
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem("UT_UI");

      let res = await api
        .post("/auth/refresh", {
          refreshToken,
          phone,
        })
        .then((res) => {
          const userData = jsonwebtoken.sign(
            {
              token: res?.data?.accessToken,
              refreshToken: res?.data?.refreshToken,
              id,
              phone,
            },
            EnvKey.secretKey
          );
          localStorage.setItem("UT_UI", userData);

          return api(originalRequest);
        })
        .catch((error) => {
          localStorage.removeItem("UT_UI");
          store.dispatch(userAction.update({ key: "name", val: "" }));
          store.dispatch(userAction.update({ key: "avatar", val: "" }));
          store.dispatch(userAction.update({ key: "nationalId", val: "" }));
          navigation.router.push("/");
        });
    }
    return Promise.reject(error);
  }
);
