import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import Link from "next/link";
import { colors } from "../utils/const";
import { styles } from "./footer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../utils/Axios";
import { useSelector, useDispatch } from "react-redux";
import Image from "next/legacy/image";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SvgIcon from "@mui/material/SvgIcon";

export default function Secondary_Navbar({ setIsOPen }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(true);
  // const [src, setSrc] = useState({
  //   twitter: "/t.svg",
  //   linkedin: "/l.svg",
  //   youtube: "/y.svg",
  //   instagram: "/i.svg",
  //   facebook: "/f.svg",
  // });
  function isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const mainTab = useSelector((state) => state.mainTab.mainTab);
  const isCompanyCheck = mainTab === "شركات";
  const dispatch = useDispatch();

  // const [disabled, setDisabled] = useState(false);
  const router = useRouter();

  const { locale } = router;
  const t = locale === "en" ? en : ar;
  // let leftOrRight = locale === "en" ? { right: "0px" } : { left: "0px" };
  let margin = locale === "en" ? "ml" : "mr";
  const year = new Date().getFullYear();
  const yearInArabic = year.toLocaleString("ar-EG", { useGrouping: false });

  const FacebookIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M17 2H14C11.8 2 10 3.8 10 6V9H7V13H10V22H14V13H17L18 9H14V6.5C14 6 14.5 5.5 15 5.5H18V2H17Z"
      />
    </SvgIcon>
  );
  const LinkedInIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9.13608 7.46653C9.13608 8.27647 8.43405 8.93306 7.56804 8.93306C6.70204 8.93306 6 8.27647 6 7.46653C6 6.65659 6.70204 6 7.56804 6C8.43405 6 9.13608 6.65659 9.13608 7.46653Z"
      />
      <path
        fill="currentColor"
        d="M6.21443 10.011H8.89485V18.1333H6.21443V10.011Z"
      />
      <path
        fill="currentColor"
        d="M13.2103 10.011H10.5299V18.1333H13.2103C13.2103 18.1333 13.2103 15.5763 13.2103 13.9775C13.2103 13.0179 13.538 12.0541 14.8454 12.0541C16.3229 12.0541 16.314 13.3099 16.3071 14.2828C16.2981 15.5545 16.3196 16.8523 16.3196 18.1333H19V13.8466C18.9773 11.1093 18.264 9.84807 15.9175 9.84807C14.524 9.84807 13.6602 10.4807 13.2103 11.0531V10.011Z"
      />
    </SvgIcon>
  );

  useEffect(() => {
    const handleRouteChange = () => {
      setEmail("");
      setError(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // Cleanup the event listener on component unmount
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  return (
    <Box
      // className="horizontal-safe-padding vertical-safe-padding mt-5"
      className="horizontal-safe-padding vertical-safe-padding"
      sx={{ bgcolor: "#EFEFEF", border: `1px solid ${colors.dark_grey}` }}
    >
      <Box sx={{ pb: 6 }}>
        <Box sx={styles.a_container}>
          <Box sx={{ textAlign: { xs: "center", sm: "initial" } }}>
            <Box sx={{ color: colors.black, fontWeight: "bold", pb: 1 }}>
              {t.footer.section_a.subscribe_now}
            </Box>

            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                fontWeight: "bold",
                color: "#667085",
                zIndex: 1,
              }}
            >
              {/* Enhance Performance and solve warning of Background and foreground colors do not have a sufficient contrast ratio.*/}
              <Box sx={styles.overlay} />
              {t.footer.section_a.in_email_service}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              ml: locale === "en" ? "auto" : "0",
              mr: locale === "ar" ? "auto" : "0",

              justifyContent: { xs: "center", sm: "space-between", gap: 15 },
            }}
          >
            <Box>
              <TextField
                placeholder={t.footer.section_a.enter_email}
                inputProps={{
                  sx: {
                    fontSize: 13,
                    fontWeight: "bold",
                    lineHeight: "2",
                  },
                }}
                sx={styles.input}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(null);
                }}
                onBlur={() => {
                  if (email.length === 0) {
                    locale === "en"
                      ? setError("Email is required")
                      : setError("برجاء ادخال البريد الالكتروني ");
                  } else if (!isValidEmail(email)) {
                    locale === "en"
                      ? setError("Email is invalid")
                      : setError("برجاء ادخال بريد الكتروني صحيح");
                  } else {
                    setError(null);
                  }
                }}
                hiddenLabel
                id="car-price"
                size="small"
              />
              {error && typeof error === "string" && (
                <Box sx={{ color: "red", fontSize: "15px", m: 1 }}>{error}</Box>
              )}
            </Box>

            <Button
              variant="outlined"
              type="email"
              aria-label="subscription button"
              sx={styles.btn}
              onClick={() => {
                if (isValidEmail(email)) {
                  // setDisabled(true);
                  api
                    .post("/general/subscribe", {
                      email,
                    })
                    .then((res) => {
                      // setDisabled(false);
                      setEmail("");
                      setError(true);
                      toast.success(t.subscribeMsg, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    })
                    .catch((err) => {
                      toast.error(t.subscribeFailMsg, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    });
                }
              }}
            >
              {t.footer.section_a.button}
            </Button>
          </Box>
        </Box>
      </Box>
      {/* Start Second Section */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          borderTop: `1px solid ${colors.dark_grey}`,
        }}
        className="section-safe-padding-top"
      >
        <Box sx={styles.container}>
          {/* Start For Individuals */}

          <Box sx={styles.sub_container}>
            <Box sx={styles.title} data-aos="fade-up">
              <Box sx={styles.overlay}>
                {t.footer.section_b.for_individuals}
              </Box>
            </Box>
            <Accordion
              className="acc-pick"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              sx={styles.link}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={styles.overlayIcon}>
                  <Typography>
                    {t.footer.section_b.financial_services}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {t.footer.section_b.list1
                  .filter((item) => item.id !== "11" && item.id !== "12")
                  .map((item, index) => (
                    <Typography
                      data-aos="fade-up"
                      component={Link}
                      href={item.url}
                      sx={styles.link}
                      style={{ paddingBottom: "5px", fontWeight: "normal" }}
                      key={index}
                    >
                      {item.name}
                    </Typography>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="acc-pick"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              sx={styles.link}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={styles.overlayIcon}>
                  <Typography>
                    {t.footer.section_b.insurance_services}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {t.footer.section_b.list2.map((item, index) => (
                  <Typography
                    data-aos="fade-up"
                    component={Link}
                    href={item.url}
                    sx={styles.link}
                    style={{ paddingBottom: "5px", fontWeight: "normal" }}
                    key={index}
                  >
                    {item.name}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
            {t.footer.section_b.list3.map((item, index) => (
              <Typography
                data-aos="fade-up"
                component={Link}
                href={item.url}
                sx={styles.link}
                // style={{ paddingBottom: "5px" }}
                key={index}
              >
                {item.name}
              </Typography>
            ))}
          </Box>

          {/* End For Individuals */}

          {/* Start For Business */}
          <Box sx={styles.sub_container}>
            <Box sx={styles.sub_container}>
              <Box sx={styles.title} data-aos="fade-up">
                <Box sx={styles.overlay}>{t.footer.section_b.for_business}</Box>
              </Box>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={styles.overlayIcon}>
                    <Typography>
                      {t.footer.section_b.financial_services}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list1
                    .filter((item) => item.id == "11" || item.id == "12")
                    .map((item, index) => (
                      <Typography
                        data-aos="fade-up"
                        component={Link}
                        href={item.url}
                        sx={styles.link}
                        style={{ paddingBottom: "5px", fontWeight: "normal" }}
                        key={index}
                      >
                        {item.name}
                      </Typography>
                    ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={styles.overlayIcon}>
                    <Typography>
                      {t.footer.section_b.insurance_services}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list2.map((item, index) => (
                    <Typography
                      data-aos="fade-up"
                      component={Link}
                      href={item.url}
                      sx={styles.link}
                      style={{ paddingBottom: "5px", fontWeight: "normal" }}
                      key={index}
                    >
                      {item.name}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={styles.overlayIcon}>
                    <Typography>{t.navbar_partnership}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.partner_dropdown.map((item, index) => (
                    <Typography
                      data-aos="fade-up"
                      component={Link}
                      href={item.url}
                      sx={styles.link}
                      style={{ paddingBottom: "5px", fontWeight: "normal" }}
                      key={index}
                    >
                      {item.title}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          {/* End For Business */}

          {/* Start About Contact*/}
          <Box sx={styles.sub_container}>
            <Box sx={styles.title} data-aos="fade-up">
              <Box sx={styles.overlay}>{t.footer.section_b.contact}</Box>
            </Box>

            {t.footer.section_b.contact_list.map((item, index) => (
              <Box
                data-aos="fade-up"
                component={Link}
                href={item.url}
                sx={styles.link}
                key={index}
              >
                {item.name}
              </Box>
            ))}
          </Box>
          {/* End About Contact */}

          {/* Start Privacy*/}
          <Box sx={styles.sub_container}>
            <Box sx={styles.title} data-aos="fade-up">
              <Box sx={styles.overlay}> {t.footer.section_b.privacy}</Box>
            </Box>
            {t.footer.section_b.privacy_list.map((item, index) => (
              <Box
                data-aos="fade-up"
                // component={Link}
                component="a"
                target="_blank"
                href={item.url}
                sx={styles.link}
                key={index}
              >
                {item.name}
              </Box>
            ))}
            <Accordion
              className="acc-pick"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              sx={styles.link}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={styles.overlayIcon}>
                  <Typography>{t.footer.section_b.protection_guide}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={styles.sub_container}>
                {t.footer.section_b.protection_guide_list.map((item, index) => (
                  <Box
                    data-aos="fade-up"
                    component={Link}
                    target="_blank"
                    href={item.url}
                    sx={styles.link}
                    style={{ paddingBottom: "5px", fontWeight: "normal" }}
                    key={index}
                  >
                    {item.name}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
            {router.asPath.includes(`/products/contact-now`) && (
              <Box
                data-aos="fade-up"
                component={Link}
                target="_blank"
                href={
                  // "https://contact.eg/wp-content/uploads/2020/11/Digital-Terms-and-Conditions-Form-03.pdf"
                  t.terms
                }
                sx={styles.link}
              >
                {t.footer.section_b.Terms_and_Conditions}
              </Box>
            )}
          </Box>
          {/* End Privacy */}

          {/* Start Contact Us*/}
          <Box sx={styles.sub_container}>
            <Box
              sx={styles.title}
              data-aos="fade-up"
              component={Link}
              target="_self"
              href={"/contact-us"}
            >
              <Box sx={styles.overlay}> {t.footer.section_b.contact_us}</Box>
            </Box>
            {t.footer.section_b.contact_us_list.map((item, index) => (
              <Box
                data-aos="fade-up"
                component={index == 2 ? "Typography" : Link}
                target={index == 0 ? "_blank" : "_self"}
                href={item.url}
                sx={styles.link}
                key={index}
              >
                {item.name}
              </Box>
            ))}
          </Box>
          {/* End Contact Us*/}
        </Box>
      </Box>
      {/* section B - Mobile ------------------- */}

      <Box sx={{ display: { md: "none" } }}>
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={styles.accrodion_title}>
                {t.footer.section_b.for_individuals}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={styles.sub_container}>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {t.footer.section_b.financial_services}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list1
                    .filter((item) => item.id !== "11" && item.id !== "12")
                    .map((item, index) => (
                      <Typography
                        data-aos="fade-up"
                        component={Link}
                        href={item.url}
                        sx={styles.link}
                        style={{ paddingBottom: "5px", fontWeight: "normal" }}
                        key={index}
                      >
                        {item.name}
                      </Typography>
                    ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {t.footer.section_b.insurance_services}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list2.map((item, index) => (
                    <Typography
                      data-aos="fade-up"
                      component={Link}
                      href={item.url}
                      sx={styles.link}
                      style={{ paddingBottom: "5px", fontWeight: "normal" }}
                      key={index}
                    >
                      {item.name}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              {t.footer.section_b.list3.map((item, index) => (
                <Typography
                  data-aos="fade-up"
                  component={Link}
                  href={item.url}
                  sx={styles.link}
                  // style={{ paddingBottom: "5px" }}
                  key={index}
                >
                  {item.name}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={styles.accrodion_title}>
                {t.footer.section_b.for_business}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={styles.sub_container}>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {t.footer.section_b.financial_services}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list1
                    .filter((item) => item.id == "11" || item.id == "12")
                    .map((item, index) => (
                      <Typography
                        data-aos="fade-up"
                        component={Link}
                        href={item.url}
                        sx={styles.link}
                        style={{ paddingBottom: "5px", fontWeight: "normal" }}
                        key={index}
                      >
                        {item.name}
                      </Typography>
                    ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {t.footer.section_b.insurance_services}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {t.footer.section_b.list2.map((item, index) => (
                    <Typography
                      data-aos="fade-up"
                      component={Link}
                      href={item.url}
                      sx={styles.link}
                      style={{ paddingBottom: "5px", fontWeight: "normal" }}
                      key={index}
                    >
                      {item.name}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              {t.footer.section_b.for_business_list.map((item, index) => (
                <Box
                  data-aos="fade-up"
                  component={Link}
                  target={index == 0 ? "_blank" : "_self"}
                  href={item.url}
                  sx={styles.link}
                  key={index}
                >
                  {item.name}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={styles.accrodion_title}>
                {t.footer.section_b.contact}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={styles.sub_container}>
              {t.footer.section_b.contact_list.map((item, index) => (
                <Box
                  component={Link}
                  href={item.url}
                  sx={styles.link}
                  key={index}
                >
                  {item.name}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={styles.accrodion_title}>
                {t.footer.section_b.privacy}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={styles.sub_container}>
              {t.footer.section_b.privacy_list.map((item, index) => (
                <Box
                  data-aos="fade-up"
                  // component={Link}
                  component="a"
                  target="_blank"
                  href={item.url}
                  sx={styles.link}
                  key={index}
                >
                  {item.name}
                </Box>
              ))}
              <Accordion
                className="acc-pick"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                sx={styles.link}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t.footer.section_b.protection_guide}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={styles.sub_container}>
                  {t.footer.section_b.protection_guide_list.map(
                    (item, index) => (
                      <Box
                        data-aos="fade-up"
                        component={Link}
                        href={item.url}
                        target="_blank"
                        sx={styles.link}
                        style={{ paddingBottom: "5px", fontWeight: "normal" }}
                        key={index}
                      >
                        {item.name}
                      </Box>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
              {router.asPath.includes(`/products/contact-now`) && (
                <Box
                  data-aos="fade-up"
                  component={Link}
                  target="_blank"
                  href={
                    // "https://contact.eg/wp-content/uploads/2020/11/Digital-Terms-and-Conditions-Form-03.pdf"
                    t.terms
                  }
                  sx={styles.link}
                >
                  {t.footer.section_b.Terms_and_Conditions}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                sx={styles.accrodion_title}
                component={Link}
                target="_blank"
                href={"/contact-us"}
              >
                {t.footer.section_b.contact_us}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={styles.sub_container}>
              {t.footer.section_b.contact_us_list.map((item, index) => (
                <Box
                  data-aos="fade-up"
                  component={Link}
                  target={index == 1 ? "_blank" : "_self"}
                  href={item.url}
                  sx={styles.link}
                  key={index}
                >
                  {item.name}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box sx={styles.app_section} className="section-safe-padding-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Box
              component="img"
              src="/award.svg"
              alt="award"
              width="100px"
              height="100px"
              sx={{ mb: { xs: 1, md: 0 } }}
              loading="lazy"
            />
            <Box
              component="img"
              alt="award2"
              src="/award2.svg"
              width="100px"
              height="100px"
              loading="lazy"
            />
          </Box>
          <Box sx={styles.app_icons}>
            <a
              href="https://play.google.com/store/apps/details?id=eg.contact"
              target="_blank"
            >
              <Image
                src={t.footer.section_c.img1}
                alt="google play for contactNow"
                width="140px"
                height="45px"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088"
              target="_blank"
            >
              <Image
                src={t.footer.section_c.img2}
                alt="app store for contactNow"
                width="140px"
                height="45px"
              />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C108100655"
              target="_blank"
            >
              <Image
                src={t.footer.section_c.img3}
                alt="huawei for contactNow"
                width="140px"
                height="45px"
              />
            </a>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          position: "relative",
          width: "100%",
          marginTop: "15px",
          height: "10px",
        }}
      >
        <Image
          src="/footer-line.png"
          alt="footer line"
          sizes="100vw"
          layout="fill"
          loading="lazy"
          objectFit="contain"
        />
      </div>

      <Box sx={styles.copyright_section} style={{ paddingTop: "15px" }}>
        <Box
          sx={{
            position: "relative",
            width: "120px",
            height: "30px",
            textAlign: { xs: "center", md: "initial" },
          }}
        >
          <Image
            src={t.logo}
            alt="logo"
            sizes="100vw"
            layout="fill"
            loading="lazy"
            objectFit="contain"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            // width: "240px",
          }}
        >
          <Box
            component="a"
            href="https://x.com/contact_eg?s=21&t=G4mL-Jgpi9cT9jED7aRiVw"
            target="_blank"
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#2D317F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.5s",
              color: "white",
              "&:hover": {
                backgroundColor: "#F18222",
                transform: "scale(1.2)",
              },
            }}
          >
            {/* <XIcon sx={{ fontSize: 16 }} /> */}
            <Image src="/X-icon.svg" alt="x" width={16} height={16} />
          </Box>

          <Box
            component="a"
            href="https://www.linkedin.com/company/contact-eg/"
            target="_blank"
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#2D317F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.5s",
              color: "white",
              "&:hover": {
                backgroundColor: "#F18222",
                transform: "scale(1.2)",
              },
            }}
          >
            <LinkedInIcon sx={{ fontSize: 24 }} />
          </Box>

          <Box
            component="a"
            href="https://www.youtube.com/@Contact_eg"
            target="_blank"
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#2D317F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.5s",
              color: "white",
              "&:hover": {
                backgroundColor: "#F18222",
                transform: "scale(1.2)",
              },
            }}
          >
            <YouTubeIcon sx={{ fontSize: 16 }} />
          </Box>

          <Box
            component="a"
            href="https://www.instagram.com/contact.eg/"
            target="_blank"
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#2D317F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.5s",
              color: "white",
              "&:hover": {
                backgroundColor: "#F18222",
                transform: "scale(1.2)",
              },
            }}
          >
            <InstagramIcon sx={{ fontSize: 16 }} />
          </Box>

          <Box
            component="a"
            href="https://www.facebook.com/ContactEg"
            target="_blank"
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              backgroundColor: "#2D317F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.5s",
              color: "white",
              "&:hover": {
                backgroundColor: "#F18222",
                transform: "scale(1.2)",
              },
            }}
          >
            <FacebookIcon sx={{ fontSize: 16 }} />
          </Box>

          {/* <Box
            component="a"
            href="https://x.com/contact_eg?s=21&t=G4mL-Jgpi9cT9jED7aRiVw"
            target="_blank"
            sx={{
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Image
              src={src.twitter}
              alt="x"
              width={23}
              height={23}
              onMouseEnter={() =>
                setSrc((prev) => ({
                  ...prev,
                  twitter: `/t-2.svg`,
                }))
              }
              onMouseLeave={() =>
                setSrc((prev) => ({
                  ...prev,
                  twitter: `/t.svg`,
                }))
              }
            />
          </Box>
          <Box
            component="a"
            href="https://www.linkedin.com/company/contact-eg/"
            target="_blank"
            sx={{
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Image
              src={src.linkedin}
              alt="linkedIn"
              width={23}
              height={23}
              onMouseEnter={() =>
                setSrc((prev) => ({
                  ...prev,
                  linkedin: `/l-2.svg`,
                }))
              }
              onMouseLeave={() =>
                setSrc((prev) => ({
                  ...prev,
                  linkedin: `/l.svg`,
                }))
              }
            />
          </Box>
          <Box
            component="a"
            href="https://www.youtube.com/@Contact_eg"
            target="_blank"
            sx={{
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Image
              src={src.youtube}
              alt="youtube"
              width={23}
              height={23}
              onMouseEnter={() =>
                setSrc((prev) => ({
                  ...prev,
                  youtube: `/y-2.svg`,
                }))
              }
              onMouseLeave={() =>
                setSrc((prev) => ({
                  ...prev,
                  youtube: `/y.svg`,
                }))
              }
            />
          </Box>
          <Box
            component="a"
            href="https://www.instagram.com/contact.eg/"
            target="_blank"
            sx={{
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Image
              src={src.instagram}
              alt="instagram"
              width={23}
              height={23}
              onMouseEnter={() =>
                setSrc((prev) => ({
                  ...prev,
                  instagram: `/i-2.svg`,
                }))
              }
              onMouseLeave={() =>
                setSrc((prev) => ({
                  ...prev,
                  instagram: `/i.svg`,
                }))
              }
            />
          </Box>
          <Box
            component="a"
            href="https://www.facebook.com/ContactEg"
            target="_blank"
            sx={{
              transition: ".5s",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          >
            <Image
              src={src.facebook}
              alt="facebook"
              width={23}
              height={23}
              onMouseEnter={() =>
                setSrc((prev) => ({
                  ...prev,
                  facebook: `/f-2.svg`,
                }))
              }
              onMouseLeave={() =>
                setSrc((prev) => ({
                  ...prev,
                  facebook: `/f.svg`,
                }))
              }
            />
          </Box> */}
        </Box>

        <Box
          sx={{
            color: "#98A2B3",
            position: "relative",
            zIndex: 1,
            gap: { xs: 1, md: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            textAlign: "center",
          }}
        >
          <Box sx={styles.overlay} />
          {locale === "en"
            ? t.footer.section_d.copy_right1 +
              year +
              t.footer.section_d.copy_right2
            : t.footer.section_d.copy_right + yearInArabic}
        </Box>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={locale === "en" ? false : true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </Box>
  );
}
